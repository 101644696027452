export const data = {
    pageCount: 2,
    tasks: [
        {
            Actions: {
                custom: [],
                delete: true,
                documents: [],
                edit: true
            },
            ClientClientNameTxt: "MinMax E49ITE-10000",
            PolicyCreatedById: "smi-c8-service@hubio.ca",
            PolicyCreatedByNm: "smi-c8-service@hubio.ca",
            PolicyCreatedDateDt: "2024-07-08",
            PolicyDescriptionTxt: "NB for MinMax E49ITE-10000",
            PolicyInsuranceNm: "",
            PolicyLineOfBusinessCd: "H",
            PolicyLineOfBusinessNm: "Hab",
            PolicyModifiedByNm: "",
            PolicyModifiedDateDt: "",
            PolicyReferenceNumTxt: "HAB101027",
            PolicyStatusCd: "RA",
            PolicyStatusNm: "Rated",
            PolicyTypeCd: "FQ",
            PolicyTypeNm: "Full Quote",
            __sortByTs: "2024-07-08T17:29:42.428Z",
            id: 101027
        },
        {
            Actions: {
                custom: [],
                delete: true,
                documents: [],
                edit: true
            },
            ClientClientNameTxt: "SpecialRisk Policy",
            PolicyCreatedById: "admin@company.com",
            PolicyCreatedByNm: "admin@company.com",
            PolicyCreatedDateDt: "2024-07-08",
            PolicyDescriptionTxt: "Special Risk Policy",
            PolicyInsuranceNm: "XYZ Underwriters",
            PolicyLineOfBusinessCd: "S",
            PolicyLineOfBusinessNm: "Special Risk",
            PolicyModifiedByNm: "",
            PolicyModifiedDateDt: "",
            PolicyReferenceNumTxt: "SRP2024",
            PolicyStatusCd: "AP",
            PolicyStatusNm: "Approved",
            PolicyTypeCd: "R",
            PolicyTypeNm: "Renewal",
            __sortByTs: "2024-07-08T14:30:00.000Z",
            id: 2024
        },
        {
            Actions: {
                custom: [],
                delete: true,
                documents: [],
                edit: true
            },
            ClientClientNameTxt: "Policy ABC123",
            PolicyCreatedById: "user@insureco.com",
            PolicyCreatedByNm: "user@insureco.com",
            PolicyCreatedDateDt: "2024-07-07",
            PolicyDescriptionTxt: "Policy for ABC123",
            PolicyInsuranceNm: "Insurance Co.",
            PolicyLineOfBusinessCd: "P",
            PolicyLineOfBusinessNm: "Property",
            PolicyModifiedByNm: "manager@insureco.com",
            PolicyModifiedDateDt: "2024-07-09",
            PolicyReferenceNumTxt: "ABC123",
            PolicyStatusCd: "AC",
            PolicyStatusNm: "Active",
            PolicyTypeCd: "N",
            PolicyTypeNm: "New Policy",
            __sortByTs: "2024-07-09T08:45:12.567Z",
            id: 7890
        },
        {
            Actions: {
                custom: [],
                delete: true,
                documents: [],
                edit: true
            },
            ClientClientNameTxt: "Health Insurance",
            PolicyCreatedById: "admin@insurancecorp.com",
            PolicyCreatedByNm: "admin@insurancecorp.com",
            PolicyCreatedDateDt: "2024-07-10",
            PolicyDescriptionTxt: "Health Insurance Plan",
            PolicyInsuranceNm: "Health Insurers Inc.",
            PolicyLineOfBusinessCd: "H",
            PolicyLineOfBusinessNm: "Health",
            PolicyModifiedByNm: "",
            PolicyModifiedDateDt: "",
            PolicyReferenceNumTxt: "HI2024",
            PolicyStatusCd: "PN",
            PolicyStatusNm: "Pending",
            PolicyTypeCd: "I",
            PolicyTypeNm: "Individual",
            __sortByTs: "2024-07-10T15:20:00.000Z",
            id: 98765
        },
        {
            Actions: {
                custom: [],
                delete: true,
                documents: [],
                edit: true
            },
            ClientClientNameTxt: "Commercial Property",
            PolicyCreatedById: "user@commercialinsure.com",
            PolicyCreatedByNm: "user@commercialinsure.com",
            PolicyCreatedDateDt: "2024-07-06",
            PolicyDescriptionTxt: "Commercial Property Insurance",
            PolicyInsuranceNm: "Commercial Insure Ltd.",
            PolicyLineOfBusinessCd: "C",
            PolicyLineOfBusinessNm: "Commercial",
            PolicyModifiedByNm: "supervisor@commercialinsure.com",
            PolicyModifiedDateDt: "2024-07-08",
            PolicyReferenceNumTxt: "CP2024001",
            PolicyStatusCd: "CN",
            PolicyStatusNm: "Cancelled",
            PolicyTypeCd: "R",
            PolicyTypeNm: "Renewal",
            __sortByTs: "2024-07-08T12:00:00.000Z",
            id: 56789
        },
        {
            Actions: {
                custom: [],
                delete: true,
                documents: [],
                edit: true
            },
            ClientClientNameTxt: "Home Insurance Policy",
            PolicyCreatedById: "manager@homeinsure.com",
            PolicyCreatedByNm: "manager@homeinsure.com",
            PolicyCreatedDateDt: "2024-07-05",
            PolicyDescriptionTxt: "Home Insurance Policy",
            PolicyInsuranceNm: "Home Insurers",
            PolicyLineOfBusinessCd: "H",
            PolicyLineOfBusinessNm: "Home",
            PolicyModifiedByNm: "admin@homeinsure.com",
            PolicyModifiedDateDt: "2024-07-07",
            PolicyReferenceNumTxt: "HIP2024",
            PolicyStatusCd: "EX",
            PolicyStatusNm: "Expired",
            PolicyTypeCd: "R",
            PolicyTypeNm: "Renewal",
            __sortByTs: "2024-07-07T09:30:00.000Z",
            id: 34567
        },
        {
            Actions: {
                custom: [],
                delete: true,
                documents: [],
                edit: true
            },
            ClientClientNameTxt: "Travel Insurance",
            PolicyCreatedById: "admin@travelinsure.com",
            PolicyCreatedByNm: "admin@travelinsure.com",
            PolicyCreatedDateDt: "2024-07-11",
            PolicyDescriptionTxt: "Travel Insurance Plan",
            PolicyInsuranceNm: "Travel Insurers Inc.",
            PolicyLineOfBusinessCd: "T",
            PolicyLineOfBusinessNm: "Travel",
            PolicyModifiedByNm: "",
            PolicyModifiedDateDt: "",
            PolicyReferenceNumTxt: "TRIP2024",
            PolicyStatusCd: "IP",
            PolicyStatusNm: "In Progress",
            PolicyTypeCd: "I",
            PolicyTypeNm: "International",
            __sortByTs: "2024-07-11T11:45:00.000Z",
            id: 111222
        },
        {
            Actions: {
                custom: [],
                delete: true,
                documents: [],
                edit: true
            },
            ClientClientNameTxt: "Liability Insurance",
            PolicyCreatedById: "user@liabilityinsure.com",
            PolicyCreatedByNm: "user@liabilityinsure.com",
            PolicyCreatedDateDt: "2024-07-04",
            PolicyDescriptionTxt: "Liability Insurance Policy",
            PolicyInsuranceNm: "Liability Insurers",
            PolicyLineOfBusinessCd: "L",
            PolicyLineOfBusinessNm: "Liability",
            PolicyModifiedByNm: "admin@liabilityinsure.com",
            PolicyModifiedDateDt: "2024-07-06",
            PolicyReferenceNumTxt: "LIAB2024",
            PolicyStatusCd: "AC",
            PolicyStatusNm: "Active",
            PolicyTypeCd: "R",
            PolicyTypeNm: "Renewal",
            __sortByTs: "2024-07-06T13:00:00.000Z",
            id: 998877
        },
        {
            Actions: {
                custom: [],
                delete: true,
                documents: [],
                edit: true
            },
            ClientClientNameTxt: "Marine Insurance",
            PolicyCreatedById: "admin@marineinsure.com",
            PolicyCreatedByNm: "admin@marineinsure.com",
            PolicyCreatedDateDt: "2024-07-03",
            PolicyDescriptionTxt: "Marine Cargo Insurance",
            PolicyInsuranceNm: "Marine Insurers",
            PolicyLineOfBusinessCd: "M",
            PolicyLineOfBusinessNm: "Marine",
            PolicyModifiedByNm: "",
            PolicyModifiedDateDt: "",
            PolicyReferenceNumTxt: "MAR2024",
            PolicyStatusCd: "RA",
            PolicyStatusNm: "Rated",
            PolicyTypeCd: "I",
            PolicyTypeNm: "Individual",
            __sortByTs: "2024-07-03T10:30:00.000Z",
            id: 665544
        },
        {
            Actions: {
                custom: [],
                delete: true,
                documents: [],
                edit: true
            },
            ClientClientNameTxt: "SpecialRisk Policy",
            PolicyCreatedById: "admin@company.com",
            PolicyCreatedByNm: "admin@company.com",
            PolicyCreatedDateDt: "2024-07-08",
            PolicyDescriptionTxt: "Special Risk Policy",
            PolicyInsuranceNm: "XYZ Underwriters",
            PolicyLineOfBusinessCd: "S",
            PolicyLineOfBusinessNm: "Special Risk",
            PolicyModifiedByNm: "",
            PolicyModifiedDateDt: "",
            PolicyReferenceNumTxt: "SRP2024",
            PolicyStatusCd: "AP",
            PolicyStatusNm: "Approved",
            PolicyTypeCd: "R",
            PolicyTypeNm: "Renewal",
            __sortByTs: "2024-07-08T14:30:00.000Z",
            id: 2024
        },
        {
            Actions: {
                custom: [],
                delete: true,
                documents: [],
                edit: true
            },
            ClientClientNameTxt: "Policy ABC123",
            PolicyCreatedById: "user@insureco.com",
            PolicyCreatedByNm: "user@insureco.com",
            PolicyCreatedDateDt: "2024-07-07",
            PolicyDescriptionTxt: "Policy for ABC123",
            PolicyInsuranceNm: "Insurance Co.",
            PolicyLineOfBusinessCd: "P",
            PolicyLineOfBusinessNm: "Property",
            PolicyModifiedByNm: "manager@insureco.com",
            PolicyModifiedDateDt: "2024-07-09",
            PolicyReferenceNumTxt: "ABC123",
            PolicyStatusCd: "AC",
            PolicyStatusNm: "Active",
            PolicyTypeCd: "N",
            PolicyTypeNm: "New Policy",
            __sortByTs: "2024-07-09T08:45:12.567Z",
            id: 7890
        },
        {
            Actions: {
                custom: [],
                delete: true,
                documents: [],
                edit: true
            },
            ClientClientNameTxt: "Health Insurance",
            PolicyCreatedById: "admin@insurancecorp.com",
            PolicyCreatedByNm: "admin@insurancecorp.com",
            PolicyCreatedDateDt: "2024-07-10",
            PolicyDescriptionTxt: "Health Insurance Plan",
            PolicyInsuranceNm: "Health Insurers Inc.",
            PolicyLineOfBusinessCd: "H",
            PolicyLineOfBusinessNm: "Health",
            PolicyModifiedByNm: "",
            PolicyModifiedDateDt: "",
            PolicyReferenceNumTxt: "HI2024",
            PolicyStatusCd: "PN",
            PolicyStatusNm: "Pending",
            PolicyTypeCd: "I",
            PolicyTypeNm: "Individual",
            __sortByTs: "2024-07-10T15:20:00.000Z",
            id: 98765
        },
        {
            Actions: {
                custom: [],
                delete: true,
                documents: [],
                edit: true
            },
            ClientClientNameTxt: "Commercial Property",
            PolicyCreatedById: "user@commercialinsure.com",
            PolicyCreatedByNm: "user@commercialinsure.com",
            PolicyCreatedDateDt: "2024-07-06",
            PolicyDescriptionTxt: "Commercial Property Insurance",
            PolicyInsuranceNm: "Commercial Insure Ltd.",
            PolicyLineOfBusinessCd: "C",
            PolicyLineOfBusinessNm: "Commercial",
            PolicyModifiedByNm: "supervisor@commercialinsure.com",
            PolicyModifiedDateDt: "2024-07-08",
            PolicyReferenceNumTxt: "CP2024001",
            PolicyStatusCd: "CN",
            PolicyStatusNm: "Cancelled",
            PolicyTypeCd: "R",
            PolicyTypeNm: "Renewal",
            __sortByTs: "2024-07-08T12:00:00.000Z",
            id: 56789
        },
        {
            Actions: {
                custom: [],
                delete: true,
                documents: [],
                edit: true
            },
            ClientClientNameTxt: "Home Insurance Policy",
            PolicyCreatedById: "manager@homeinsure.com",
            PolicyCreatedByNm: "manager@homeinsure.com",
            PolicyCreatedDateDt: "2024-07-05",
            PolicyDescriptionTxt: "Home Insurance Policy",
            PolicyInsuranceNm: "Home Insurers",
            PolicyLineOfBusinessCd: "H",
            PolicyLineOfBusinessNm: "Home",
            PolicyModifiedByNm: "admin@homeinsure.com",
            PolicyModifiedDateDt: "2024-07-07",
            PolicyReferenceNumTxt: "HIP2024",
            PolicyStatusCd: "EX",
            PolicyStatusNm: "Expired",
            PolicyTypeCd: "R",
            PolicyTypeNm: "Renewal",
            __sortByTs: "2024-07-07T09:30:00.000Z",
            id: 34567
        },
        {
            Actions: {
                custom: [],
                delete: true,
                documents: [],
                edit: true
            },
            ClientClientNameTxt: "Travel Insurance",
            PolicyCreatedById: "admin@travelinsure.com",
            PolicyCreatedByNm: "admin@travelinsure.com",
            PolicyCreatedDateDt: "2024-07-11",
            PolicyDescriptionTxt: "Travel Insurance Plan",
            PolicyInsuranceNm: "Travel Insurers Inc.",
            PolicyLineOfBusinessCd: "T",
            PolicyLineOfBusinessNm: "Travel",
            PolicyModifiedByNm: "",
            PolicyModifiedDateDt: "",
            PolicyReferenceNumTxt: "TRIP2024",
            PolicyStatusCd: "IP",
            PolicyStatusNm: "In Progress",
            PolicyTypeCd: "I",
            PolicyTypeNm: "International",
            __sortByTs: "2024-07-11T11:45:00.000Z",
            id: 111222
        },
        {
            Actions: {
                custom: [],
                delete: true,
                documents: [],
                edit: true
            },
            ClientClientNameTxt: "Liability Insurance",
            PolicyCreatedById: "user@liabilityinsure.com",
            PolicyCreatedByNm: "user@liabilityinsure.com",
            PolicyCreatedDateDt: "2024-07-04",
            PolicyDescriptionTxt: "Liability Insurance Policy",
            PolicyInsuranceNm: "Liability Insurers",
            PolicyLineOfBusinessCd: "L",
            PolicyLineOfBusinessNm: "Liability",
            PolicyModifiedByNm: "admin@liabilityinsure.com",
            PolicyModifiedDateDt: "2024-07-06",
            PolicyReferenceNumTxt: "LIAB2024",
            PolicyStatusCd: "AC",
            PolicyStatusNm: "Active",
            PolicyTypeCd: "R",
            PolicyTypeNm: "Renewal",
            __sortByTs: "2024-07-06T13:00:00.000Z",
            id: 998877
        },
        {
            Actions: {
                custom: [],
                delete: true,
                documents: [],
                edit: true
            },
            ClientClientNameTxt: "Marine Insurance",
            PolicyCreatedById: "admin@marineinsure.com",
            PolicyCreatedByNm: "admin@marineinsure.com",
            PolicyCreatedDateDt: "2024-07-03",
            PolicyDescriptionTxt: "Marine Cargo Insurance",
            PolicyInsuranceNm: "Marine Insurers",
            PolicyLineOfBusinessCd: "M",
            PolicyLineOfBusinessNm: "Marine",
            PolicyModifiedByNm: "",
            PolicyModifiedDateDt: "",
            PolicyReferenceNumTxt: "MAR2024",
            PolicyStatusCd: "RA",
            PolicyStatusNm: "Rated",
            PolicyTypeCd: "I",
            PolicyTypeNm: "Individual",
            __sortByTs: "2024-07-03T10:30:00.000Z",
            id: 665544
        },
        {
            Actions: {
                custom: [],
                delete: true,
                documents: [],
                edit: true
            },
            ClientClientNameTxt: "MinMax E49ITE-10000",
            PolicyCreatedById: "smi-c8-service@hubio.ca",
            PolicyCreatedByNm: "john.doe@example.com",
            PolicyCreatedDateDt: "2024-07-09",
            PolicyDescriptionTxt: "NB for MinMax E49ITE-10000",
            PolicyInsuranceNm: "ABC Insurance",
            PolicyLineOfBusinessCd: "A",
            PolicyLineOfBusinessNm: "Auto",
            PolicyModifiedByNm: "alice.smith@example.com",
            PolicyModifiedDateDt: "2024-07-10",
            PolicyReferenceNumTxt: "AUTO12345",
            PolicyStatusCd: "IP",
            PolicyStatusNm: "In Progress",
            PolicyTypeCd: "Q",
            PolicyTypeNm: "Quote",
            __sortByTs: "2024-07-10T10:15:30.123Z",
            id: 12345

        },
        {
            Actions: {
                custom: [],
                delete: true,
                documents: [],
                edit: true
            },
            ClientClientNameTxt: "SpecialRisk Policy",
            PolicyCreatedById: "admin@company.com",
            PolicyCreatedByNm: "admin@company.com",
            PolicyCreatedDateDt: "2024-07-08",
            PolicyDescriptionTxt: "Special Risk Policy",
            PolicyInsuranceNm: "XYZ Underwriters",
            PolicyLineOfBusinessCd: "S",
            PolicyLineOfBusinessNm: "Special Risk",
            PolicyModifiedByNm: "",
            PolicyModifiedDateDt: "",
            PolicyReferenceNumTxt: "SRP2024",
            PolicyStatusCd: "AP",
            PolicyStatusNm: "Approved",
            PolicyTypeCd: "R",
            PolicyTypeNm: "Renewal",
            __sortByTs: "2024-07-08T14:30:00.000Z",
            id: 2024
        }
    ]
}