import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';
import { DashboardRoutingModule } from './dashboard-routing.module';

import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { SearchResultsComponent } from './components/search-results/search-results.component';
import { NewPolicyModalComponent } from './components/new-policy-modal/new-policy-modal.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatMenuModule } from '@angular/material/menu';

import { MatNativeDateModule, MatDatepickerModule, MatListModule, MatSelectModule, MatRadioModule, MatSortModule, MatCheckboxModule, MatExpansionModule, MatSidenavModule, MatInputModule, MatTableModule, MatPaginatorModule, MatIconModule, MatButtonModule, MatTooltipModule, MatDialogModule, MatProgressSpinnerModule, MAT_DATE_FORMATS, MatTabsModule, MatPaginatorIntl } from '@angular/material';
import { FilterBarComponent } from './components/filter-bar/filter-bar.component';
import { DisclaimerDialogComponent } from './components/dialogs/disclaimer-dialog/disclaimer-dialog.component';
import { AnalyticsComponent } from './pages/analytics/analytics.component';
import { DashboardNavBarComponent } from './components/dashboard-nav-bar/dashboard-nav-bar.component';
import { MatFormFieldModule } from '@angular/material';
import { FilterInputComponent } from './components/filter-input/filter-input.component';
import { GraphComponent } from './components/graph/graph.component';
import { DashboardPaginator } from './modules/paginator';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { PoliciesInForceComponent } from './pages/policies-in-force/policies-in-force.component';

import { HubioCommonPagesModule } from '@hubio/hubio-common-components';
import { Communic8DownloadComponent } from './components/communic8-download/communic8-download.component';

@NgModule({
  declarations: [
    DashboardComponent,
    SearchBarComponent,
    SearchResultsComponent,
    NewPolicyModalComponent,
    FilterBarComponent,
    DisclaimerDialogComponent,
    AnalyticsComponent,
    DashboardNavBarComponent,
    FilterInputComponent,
    GraphComponent,
    PoliciesInForceComponent,
    Communic8DownloadComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    HubioCommonPagesModule,
    DashboardRoutingModule,
    SatDatepickerModule,
    SatNativeDateModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSortModule,
    MatListModule,
    MatInputModule,
    MatTableModule,
    MatTabsModule,
    MatPaginatorModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatDialogModule,
    MatSidenavModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule
  ],
  entryComponents: [
    NewPolicyModalComponent,
    DisclaimerDialogComponent
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: DashboardPaginator
    }
  ]
})
export class DashboardModule { }
