import { PolicyService } from '@modules/policy/policy.service';
import { SpinnerService } from './../../../../core/services/spinner.service';
import { DocumentService } from './../../../../core/services/document.service';
import { Component, ViewChild, OnInit, ViewEncapsulation } from '@angular/core';
import { PolicyCreationService } from '../../services/policy.service';
import { MatDialog, MatSort, MatPaginator, MatDrawer, PageEvent } from '@angular/material';
import { FilterService } from '../../services/filter.service'
import { Observable, Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { BasicDialogComponent } from '@modules/policy/shared/components/basic-dialog/basic-dialog.component';
import { MetadataService } from '@core/services/metadata.service';
import { PolicyDocument } from '@shared/models/policy-document.model';
import { PolicyDataService } from '@modules/policy/services/shared/policy-data.service';
import { QuickFilterItem, TableConfig } from '@shared/models/feMetadata.model';
import { DialogForm } from '@modules/policy/shared/components/dialog-form/dialog-form.component';
import { JsonFormFetcherService } from '@modules/policy/services/shared/json-form-fetcher.service';
import { FormTabService } from 'form-tab';
import { mergeMap } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { CustomActionService } from '@core/services/custom-action.service';
import { HubioTableOptions, HubioTableComponent } from '@hubio/hubio-common-components';
import { DashboardService } from '@modules/dashboard/services/dashboard.service';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchResultsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('filterDrawer', { static: false }) public drawer: MatDrawer;
  @ViewChild('table', { static: false }) public table: HubioTableComponent;

  displayedColumns: string[];
  brokerages;
  transactionsHeight: string;
  filtersApplied = {};
  filtersInitialized: boolean = false;
  filterEventsSub: Subscription
  getPoliciesSub: Subscription;
  totalPolicies: number;
  currentQuickFilter: Observable<string | null>;
  quickFilterConfig: Array<QuickFilterItem>;
  tableConfig: TableConfig;
  tableOptions: HubioTableOptions;
  isCreated: boolean = false;
  workbenchConfig: any;
  public actionsConfig: any;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private policyCreationService: PolicyCreationService,
    public filterService: FilterService,
    public SpinnerService: SpinnerService,
    private PolicyService: PolicyService,
    private PolicyDataService: PolicyDataService,
    private DocumentService: DocumentService,
    private MetadataService: MetadataService,
    private jsonFormFetcherService: JsonFormFetcherService,
    private formTabService: FormTabService,
    private customActionService: CustomActionService,
    private dashboardService: DashboardService,
    private activatedRoute: ActivatedRoute
  ) {
    this.filterEventsSub = this.filterService.events.subscribe(event => {
      if (this.isCreated) {
        this.handleFilterEvent(event);
      } else {
        this.filtersApplied = event.filter;
      }
    });
  }

  ngOnInit() {
    this.initMetadata();
    this.currentQuickFilter = this.filterService.getQuickFilter()
    this.actionsConfig = this.MetadataService.getWorkbenchActions();
    this.handleInitialFilters();
    this.initComponent();
  }

  initComponent() {
    this.initTableOptions();
    this.PolicyService.clearForms();
    this.getPolicies(this.filtersApplied, 1, true);
    this.brokerages = this.policyCreationService.getBrokers();
  }

  initTableOptions() {
    this.tableOptions = {
      columns: this.tableConfig.columns,
      tooltip: this.tableConfig.tooltip,
      minHeight: this.transactionsHeight,
      paginator: {
        paginate: true,
        pageSize: this.dashboardService.PAGE_SIZE
      },
      cache: {
        cache: true,
        maxCacheSize: this.workbenchConfig.maxPagesCached
      }
    };
  }

  initMetadata() {
    this.workbenchConfig = this.MetadataService.getWorkbenchMetaData();
    this.quickFilterConfig = this.MetadataService.getQuickFiltersMetaData();
    this.tableConfig = this.MetadataService.getWorkbenchTableConfig();
    this.displayedColumns = this.tableConfig.columns.map(({ name }) => name);
  }

  ngAfterViewInit(): void {
    this.filterService.setDrawer(this.drawer);
    this.drawer.openedStart.subscribe(() => this.filtersInitialized = true);
  }

  ngAfterContentChecked(): void {
    if (this.transactionsHeight) return;
    const setHeight = (230 - 56)
      + (this.dashboardService.hasTabs() ? 50 : 0)
      + (this.quickFilterConfig ? 30 : 0);
    this.transactionsHeight = `calc(100vh - ${setHeight}px)`;
    this.tableOptions.minHeight = this.transactionsHeight;
  }

  deletePolicy(policy): void {
    this.dialog.open(BasicDialogComponent, {
      data: {
        Message: `Do you really want to delete policy ${policy.PolicyReferenceNumTxt}? ` +
          `This policy will be permanently deleted.`,
        title: 'Delete Policy?',
        dialogPrefix: 'delete-policy-modal'
      },
      width: '350px'
    }).afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.policyCreationService.deletePolicy(policy.id).subscribe((_: any) => {
          this.initComponent();
        })
      }
    });
  }

  navigateAction(policy) {
    this.policyCreationService.editPolicy(policy.id).toPromise().then(({ config }) => {
      let editUrl = '';
      switch (policy.PolicyStatusCd) {
        case 'UP':
        case 'UN':
        case 'RA':
        case 'RJ':
        case 'RE':
          this.PolicyDataService.updatePolicyConfig(config)
          editUrl = config.defaultRoute.replace(/:policyId/ig, policy.id);
          break;
        case 'LCK':
          editUrl = `/billing/${policy.id}`;
          break;
      }
      this.PolicyDataService.assignPolicyReadOnly(policy)
      this.router.navigate([editUrl]);
    })
  }

  handleFilterEvent(event) {
    const { searchText, filter, clear } = event;
    if (filter) this.getPolicies(filter, 1, true);
    if (searchText) this.getPolicies(this.filtersApplied, 1, true);
    if (clear) this.clearFilters();
  }

  getPolicies(filters: any, pageNum?: number, refresh: boolean = false) {
    if (refresh && this.table) {
      this.table.resetCache();
      this.table.paginator.firstPage();
    }

    this.getPoliciesSub = this.policyCreationService.getPolicies(filters, pageNum).subscribe(res => {
      const { policies, pageCount } = res;
      // pageNum - 1 to offset BE pageNum indexing at 1
      this.table.setData(policies, pageNum - 1);
      // Only set length on first page
      if (pageNum == 1) {
        this.tableOptions.paginator.length = policies.length * pageCount;
      }
      this.filtersApplied = filters;

      if (!this.isCreated) {
        this.isCreated = true;
      }
    });
  }

  handlePaginationEvent(event: PageEvent) {
    if (event.pageIndex > 0) {
      this.getPolicies(this.filtersApplied, event.pageIndex + 1);
    }
  }

  clearFilters() {
    this.policyCreationService.searchValue = '';
    this.getPolicies({}, 1, true);
  }

  applyQuickFilters(filters: Array<any>, quickFilterName: string, emitEvent = true) {
    this.filterService.updateQuickFilter(quickFilterName);
    this.filtersApplied = {};
    for (const { field, value } of filters) {
      this.filtersApplied[field] = value;
    }
    if (emitEvent) this.filterService.newEvent({ filter: this.filtersApplied, quickFilter: quickFilterName });
  }

  handleInitialFilters() {
    const queryParams = this.activatedRoute.queryParams['value'];
    if (queryParams) {
      const searchKey = 'searchtext';
      const searchText = queryParams[Object.keys(queryParams).find((key) => key.toLowerCase() === searchKey)];

      if (searchText) {
        this.policyCreationService.searchValue = searchText;
        return;
      }
    }

    if (Object.keys(this.filtersApplied).length > 0) return;
    else if (Object.keys(this.filterService.lastAppliedFilters).length > 0) {
      this.filtersApplied = this.filterService.lastAppliedFilters
      return;
    }
    if (!this.quickFilterConfig) return;

    for (const { defaultApply, label, appliedFilters } of this.quickFilterConfig) {
      if (defaultApply) {
        this.applyQuickFilters(appliedFilters, label, false);
        break;
      }
    }
  }


  getDocument(policyId: string, category: string) {
    this.DocumentService.getPolicyDocument(policyId, category).subscribe((res: PolicyDocument) => {
      this.DocumentService.openDocument(res);
    });
  }

  getCustomActionConfig(action: string) {
    return this.workbenchConfig.customActions[action] || {};
  }

  handleCustomAction(policyItem: any, action: string) {
    const actionConfig = this.getCustomActionConfig(action);

    if (actionConfig.modal) {
      this.jsonFormFetcherService.getConfig(policyItem.id, actionConfig.modal.formName).pipe(
        mergeMap(result => {
          return this.formTabService.initForm(JSON.stringify(result), JSON.stringify({}))
        }),
      ).subscribe(res => {
        this.dialog.open(DialogForm, {
          panelClass: 'custom-action-dialog',
          data: {
            form: res.form,
            formJson: res.orderedFormJson
          }
        }).afterClosed().subscribe(res => {
          if (res instanceof FormGroup) {
            let payload = {};
            const rawForm = res.getRawValue();
            for (let key in rawForm) {
              payload = {
                ...payload,
                ...rawForm[key]
              };
            }

            this.customActionService.executeCustomAction(policyItem.id, payload, actionConfig.endpoint).subscribe(
              (res) => {
                this.customActionService.displaySuccessDialog(actionConfig.successMessage, res).subscribe((_) => {
                  this.getPolicies(this.filtersApplied, 1, true);
                });
              });
            return;
          }
        });
      });
    } else if (actionConfig.successAction) {
      this.customActionService.executeCustomAction(policyItem.id, {}, actionConfig.endpoint).toPromise()
        .then((_) => {
          this.handleSuccessAction(actionConfig.successAction, policyItem);
        });
    }
  }

  private handleSuccessAction(action: string, actionPayload: any) {
    switch (action) {
      case 'edit-policy':
        this.navigateAction(actionPayload);
        break;
      default:
        break;
    }
  }

  ngOnDestroy(): void {
    if (this.filterEventsSub) this.filterEventsSub.unsubscribe();
    if (this.getPoliciesSub) this.getPoliciesSub.unsubscribe();
    this.filterService.updateAppliedFiltersState(this.filtersApplied);
  }
}
