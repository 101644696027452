import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer, MatPaginator, MatSort, PageEvent } from '@angular/material';
import { MetadataService } from '@core/services/metadata.service';
import { HubioTableComponent, HubioTableOptions } from '@hubio/hubio-common-components';
import { DashboardService } from '@modules/dashboard/services/dashboard.service';
import { TableConfig } from '@shared/models/feMetadata.model';
import { data } from './stubbed-data';
import { PolicyService } from '@modules/policy/policy.service';

@Component({
  selector: 'app-communic8-download',
  templateUrl: './communic8-download.component.html',
  styleUrls: ['./communic8-download.component.scss']
})
export class Communic8DownloadComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filterDrawer', { static: true }) public drawer: MatDrawer;
  @ViewChild('table', { static: true }) public table: HubioTableComponent;

  public actionsConfig: any;
  public tableOptions: HubioTableOptions;
  private tableConfig: TableConfig;
  private transactionsHeight: string;
  private downloadConfig: any;
  private displayedColumns: string[];
  private data = data;

  constructor(
    private MetadataService: MetadataService,
    private dashboardService: DashboardService,
    private PolicyService: PolicyService
  ) { }

  ngOnInit() {
    this.initMetadata();
    this.actionsConfig = [];
    this.initComponent();
  }

  initMetadata() {
    this.downloadConfig = this.MetadataService.getDownloadMetaData();
    this.tableConfig = this.MetadataService.getDownloadTableConfig();
    this.displayedColumns = this.tableConfig.columns.map(({ name }) => name);
  }

  private initComponent() {
    this.PolicyService.clearForms();
    this.initTableOptions();
    this.getTasks({}, 1, true)
  }

  private initTableOptions() {
    this.tableOptions = {
      columns: this.tableConfig.columns,
      tooltip: this.tableConfig.tooltip,
      minHeight: this.transactionsHeight,
      paginator: {
        paginate: true,
        pageSize: this.dashboardService.PAGE_SIZE
      },
      cache: {
        cache: true,
        maxCacheSize: this.downloadConfig.maxPagesCached
      }
    };
  }

  ngAfterContentChecked(): void {
    if (this.transactionsHeight) return;
    const setHeight = (230 - 56)
      + (this.dashboardService.hasTabs() ? 50 : 0)
    this.transactionsHeight = `calc(100vh - ${setHeight}px)`;
    this.tableOptions.minHeight = this.transactionsHeight;
  }

  getTasks(filters: any, pageNum?: number, refresh: boolean = false) {
    setTimeout(() => {
      if (refresh && this.table) {
        this.table.resetCache();
        this.table.paginator.firstPage();
      }
      const { tasks, pageCount } = data;
      this.table.setData(tasks, pageNum - 1); // pageNum - 1 to offset BE pageNum indexing at 1
      if (pageNum == 1) { // Only set length on first page
        this.tableOptions.paginator.length = tasks.length * pageCount;
      }
    }, 0)

    // const { tasks, pageCount } = data;
    // this.table.setData(tasks, pageNum - 1); // pageNum - 1 to offset BE pageNum indexing at 1
    // if (pageNum == 1) { // Only set length on first page
    //   this.tableOptions.paginator.length = tasks.length * pageCount;
    // }

    // this.filtersApplied = filters;

    // if (!this.isCreated) {
    //   this.isCreated = true;
    // }
  }

  handlePaginationEvent(event: PageEvent) {
    if (event.pageIndex > 0) {
      this.getTasks({}, event.pageIndex + 1);
    }
  }

}
