import { Injectable } from '@angular/core';
import { MetadataService } from '@core/services/metadata.service';
import { Tab } from '@shared/models/feMetadata.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private tabs: Tab[];
  public readonly PAGE_SIZE = 20;
  
  constructor(private metadataService: MetadataService) { }

  handleTabs() {
    if (!this.tabs) {
      this.tabs = this.metadataService.getDashboardTabs();
    }

    if (this.tabs?.length > 0) {
      return this.tabs;
    }
  }

  hasTabs(): boolean {
    return Boolean(this.tabs?.length > 1);
  }
}
