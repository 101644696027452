import { Component, OnInit, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { MetadataService } from '@core/services/metadata.service';
import { SpinnerService } from '@core/services/spinner.service';
import { DashboardService } from '@modules/dashboard/services/dashboard.service';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterContentChecked {
    tabs: any[];
    showSpinner: Observable<boolean> = of(true);

    constructor(
        private dashboardService: DashboardService, 
        private SpinnerService: SpinnerService,
        private changeDetector: ChangeDetectorRef,
        private router: Router
    ) { }

    ngOnInit() {
        if (!this.tabs) this.tabs = this.dashboardService.handleTabs();
    }

    ngAfterContentInit(): void {
        this.showSpinner = this.SpinnerService.visibility.asObservable();
    }

    ngAfterContentChecked(): void {
        this.validateRouting();
        this.changeDetector.detectChanges();
    }

    private validateRouting(): void {
        let url = this.router.url;
        if (this.tabs) {
            let validURL = false
            for (let tab of this.tabs) {
                if (tab.fullRoute == url) validURL = true
            }
            if (!validURL) this.router.navigate([this.tabs[0].fullRoute]);
        } else this.router.navigate(["error/403"]);
    }
}
